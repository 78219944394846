<template>
  <router-view v-if="web_app.platform !== 'unknown' && is_there_key && !loading"/>
  <div v-else-if="!is_there_key" class="min-h-screen flex justify-center items-center">
    <div class="text-2xl text-center"><el-icon class="animate-spin"><RefreshRight /></el-icon></div>
  </div>
  <div v-else-if="!loading" class="min-h-screen flex justify-center items-center">
    <el-empty :description="text.key_not_found" />
  </div>
</template>

<script>
	import axios from 'axios'
  import store from '@/store';

	export default {
		data() {
			return {
				web_app: window.Telegram.WebApp,
				is_there_key: false,
        loading: true,
        user: null,
        text: null,
			}
		},
		mounted(){
      this.web_app.expand();
			if(this.web_app.platform !== 'unknown'){
				this.$router.isReady().then(() => {
					if(this.$route.query.key){
						this.$cookies.set('key', this.$route.query.key);
						this.is_there_key = true;
					}
					if(this.$cookies.get('key')){
						axios.defaults.headers["Authorization"] = `Token ${this.$cookies.get('key')}`;
						this.is_there_key = true;
					}
          if(this.$route.query.is_instant){
            store.commit('setIsInstant', true);
          }else{
            window.Telegram.WebApp.enableClosingConfirmation();
          }
          this.loadUser();
				})
			}
		},
    methods: {
      async loadUser(){
        await axios.get('/users/me/').then((response) => {
          this.user = this.initializeCurrentLanguageFields(response.data.text.language, response.data);
          this.text = this.user.text;
          store.commit('setUser', this.user);
          store.commit('setText', this.text);
          this.loading = false;
          if(!this.user.selected_branch){
            store.commit('setIsOrderTypeChangingAvailable', true);
          }
        }).catch(() => {})
      },
      isObject(value) {
          return (
              typeof value === 'object' &&
              value !== null &&
              !Array.isArray(value)
          );
      },
      initializeCurrentLanguageFields(language, data){
          if(this.isObject(data)){
              for(let key in data){
                  if(key.endsWith(`_${language}`)){
                      data[key.split('_')[0]] = data[key];
                  }else if(Array.isArray(data[key])){
                      data[key] = this.initializeCurrentLanguageFields(language, data[key]);
                  }else if(this.isObject(data[key])){
                      data[key] = this.initializeCurrentLanguageFields(language, data[key]);
                  }
              }
          }else if(Array.isArray(data)){
              for(let index in data){
                  if(this.isObject(data[index])){
                      data[index] = this.initializeCurrentLanguageFields(language, data[index]);
                  }
              }
          }
          return data;
      },
    }
	}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
	font-family: 'Roboto Condensed', sans-serif;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
